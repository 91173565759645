import { EventEmitter } from "events";

export interface ToastCustomProgress {
  toastId: string;
  customProgress?: number;
  message?: string;
}

declare interface ToastCustomProgressEmitter {
  on(
    event: "onCustomProgress",
    listener: ({
      toastId,
      message,
      customProgress,
    }: ToastCustomProgress) => any,
  ): this;
}

class ToastCustomProgressEmitter extends EventEmitter {
  constructor() {
    super();
  }

  setCustomProgress = ({
    toastId,
    customProgress,
    message,
  }: ToastCustomProgress) => {
    if (!toastId) {
      return;
    }
    this.emit("onCustomProgress", {
      toastId,
      customProgress,
      message,
    });
  };
}

export const toastCustomProgressEmitter = new ToastCustomProgressEmitter();
