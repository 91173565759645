import type { ReactNode } from "react";
import { forwardRef, useEffect } from "react";
import classNames from "classnames";
import { ProgressIcon } from "@circle-react-shared/uikit/ToastV2/ProgressIcon";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { durations } from "./constants";
import type { ToastDurationValue } from "./interfaces";

export interface CustomProgressToastProps {
  message: ReactNode;
  progress: number;
  handleClose: () => void;
  checkmarkDuration?: ToastDurationValue;
}

export const CustomProgressToast = forwardRef<
  HTMLOutputElement,
  CustomProgressToastProps
>(
  (
    { message, progress, handleClose, checkmarkDuration = durations.default },
    ref,
  ) => {
    const isDataProccessed = progress === 100;

    useEffect(() => {
      if (!isDataProccessed) {
        return;
      }
      const hideToastTimeout = setTimeout(() => {
        handleClose();
      }, checkmarkDuration);

      return () => {
        clearTimeout(hideToastTimeout);
      };
    }, [isDataProccessed, checkmarkDuration, handleClose]);

    return (
      <output
        ref={ref}
        className={classNames(
          "bg-circle-button mx-4 flex max-w-lg items-center gap-2.5 rounded-full px-4 py-2.5 pr-5 shadow-md md:mx-0",
        )}
        data-testid="flash-notice"
      >
        <ProgressIcon isIndefinite={!progress} progress={progress} />
        <Typography.LabelSm
          weight="medium"
          data-testid="flash-notice"
          color="text-circle-button"
        >
          {message}
        </Typography.LabelSm>
      </output>
    );
  },
);

CustomProgressToast.displayName = "CustomProgressToast";
