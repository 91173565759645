import type { ReactNode } from "react";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { isFunction } from "lodash";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { Icon } from "@circle-react-shared/Icon";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Typography } from "@circle-react-uikit/Typography";
import { ProgressIcon } from "./ProgressIcon";
import { animationsSlideDown, animationsSlideUp } from "./animations";
import { durations, placements, variants } from "./constants";
import { useToastMessage } from "./hooks/useToastMessage";
import type {
  ToastDuration,
  ToastDurationValue,
  ToastVariant,
} from "./interfaces";

export interface ToastMessageProps {
  message: string;
  progress?: number;
  id?: string;
  variant?: ToastVariant;
  messageComplete?: string;
  duration?: ToastDuration;
  checkmarkDuration?: ToastDuration;
  shouldUseProgress?: boolean;
  shouldSanitizeMessage?: boolean;
  shouldAutohide?: boolean;
  shouldShowCloseButton?: boolean;
  shouldUseIndefiniteIcon?: boolean;
  className?: string;
  onRemove?: () => void;
  onComplete?: () => void;
  onProgress?: (progress: number) => number;
  render?: (args: {
    message: ReactNode;
    progress: number;
    isShowing: boolean;
    handleClose: () => void;
    checkmarkDuration?: ToastDurationValue;
  }) => ReactNode | null;
}

export const ToastMessage = (args: ToastMessageProps) => {
  const {
    message: messageProp,
    progress: progressProp,
    variant = variants.success,
    messageComplete,
    duration: durationProp = "default",
    checkmarkDuration: checkmarkDurationProp = "short",
    shouldUseProgress = true,
    shouldSanitizeMessage = true,
    shouldAutohide = true,
    shouldShowCloseButton = false,
    shouldUseIndefiniteIcon = false,
    className,
    onRemove,
    onComplete,
    onProgress,
    render,
  } = args;

  const isSuccessVariant = variant === variants.success;
  const isDangerVariant = variant === variants.danger;
  const shouldDisplayProgressIcon = shouldUseProgress && !isDangerVariant;

  const duration = durations[durationProp] || durations.default;
  const checkmarkDuration = durations[checkmarkDurationProp] || durations.short;

  const { placement } = useToast();
  const { isShowing, progress, handleClose } = useToastMessage({
    duration,
    checkmarkDuration,
    shouldAutohide,
    shouldUseProgress,
    onRemove,
    onComplete,
    onProgress,
  });

  const message = shouldSanitizeMessage ? (
    <SanitizeHTMLString content={messageProp} />
  ) : (
    messageProp
  );

  return (
    <Transition
      show={isShowing}
      as={Fragment}
      appear
      {...(placement === placements.bottom
        ? animationsSlideUp
        : animationsSlideDown)}
    >
      {isFunction(render) ? (
        render({
          ...args,
          message,
          progress: progressProp ?? progress,
          isShowing,
          handleClose: handleClose || (() => {}),
          checkmarkDuration,
        })
      ) : (
        <output
          className={classNames(
            "mx-4 flex max-w-lg items-center justify-between gap-4 rounded-[1.3rem] px-4 py-2.5 shadow-md md:mx-0",
            "[&_a]:text-circle-button [&_a]:hover:text-circle-button [&_a]:focus:text-circle-button [&_a]:active:text-circle-button [&_a]:underline",
            {
              "bg-circle-button": isSuccessVariant,
              "bg-v2-danger": isDangerVariant,
              "pl-4 pr-5": !shouldShowCloseButton && shouldDisplayProgressIcon,
            },
            className,
          )}
        >
          <span className="flex gap-2.5">
            {shouldDisplayProgressIcon && (
              <span className="flex">
                <ProgressIcon
                  progress={progress}
                  variant={variant}
                  isIndefinite={shouldUseIndefiniteIcon}
                />
              </span>
            )}

            <span className={classNames("flex")}>
              <Typography.LabelSm
                weight="medium"
                data-testid="flash-notice"
                color={classNames({
                  "text-circle-button": isSuccessVariant,
                  "text-white": isDangerVariant,
                })}
              >
                {progress === 100 && messageComplete
                  ? messageComplete
                  : message}
              </Typography.LabelSm>
            </span>
          </span>

          {shouldShowCloseButton && (
            <button
              type="button"
              onClick={handleClose}
              className={classNames(
                "-mr-0.5 flex opacity-60 transition-opacity hover:opacity-100",
                {
                  "hover:bg-circle-button": isSuccessVariant,
                  "hover:bg-v2-danger": isDangerVariant,
                },
              )}
            >
              <span className="flex">
                <Icon
                  type="20-close-circle"
                  size={20}
                  className={classNames({
                    "text-circle-button": isSuccessVariant,
                    "text-white": isDangerVariant,
                  })}
                />
              </span>
            </button>
          )}
        </output>
      )}
    </Transition>
  );
};
